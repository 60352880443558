




























































































































































import {
    User
} from "@/store/user";
import {
    Auth
} from "@/store/auth";
import {
    Core
} from "@/store/core";
import {
    userRouter
} from '@/router/user'
import {
    Component,
    Vue,
    Watch
} from "vue-property-decorator";
import moment from 'moment'
import _ from 'lodash'
@Component({
    components: {

    },
    computed: {}
})

export default class UserClass extends Vue {
    publicAgency: any = 41
    drawer: any = true
    group: any = 5
    items: any = []
    user: any = null
    homeList: any = []
    oitList: any = []
    iitList: any = []
    eitList: any = []
    myAgency: any = 0
    response:boolean = false

    async created() {
        this.response = false
        await this.loadRouter();
        //console.log(this.$route)
        this.drawer = !this.$vuetify.breakpoint.mobile
          this.response = true
    }

    async loadRouter() {
        this.user = await User.getUser();
        this.myAgency = (this.user.ext_link) ? this.user.ext_link.agency : 0 
        let routerAll: any = _.filter(userRouter, {
            enabled: true
        })
        this.homeList = _.filter(routerAll, {
            group: 'home'
        })
        this.oitList = _.filter(routerAll, {
            group: 'oit'
        })
        this.iitList = _.filter(routerAll, {
            group: 'iit'
        })
        this.eitList = _.filter(routerAll, {
            group: 'eit'
        })
        this.items = routerAll
        //console.log(routerAll);
    }

    async logout() {
        let user = await User.getUser();
        //console.log(user)
        await Auth.logout();
        if (user.register_type == 'microsoft.com') {
            window.open('https://login.microsoftonline.com/logout.srf', '_blank');
        }
        await this.$router.replace('/')
        await location.reload()
    }

  

}
