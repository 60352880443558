












import {User} from "@/store/user";
import {Auth} from "@/store/auth";
import {Core} from "@/store/core";
import {Web} from "@/store/web";
import {Component, Vue, Watch} from "vue-property-decorator";

@Component({
  components: {

  },
  computed: {}
})

export default class UserClass extends Vue {

  overlay:any =  true


  async created() {

  }

  get loading(){
    return Web.loading
  }

  get loadTxt(){ 
    return Web.loadTxt
  }
  
}
