










































import {
  Component,
  Vue
} from 'vue-property-decorator';
import {
  Core
} from '@/store/core'
import {
  Auth
} from '@/store/auth'
import {
  User
} from '@/store/user'
import _ from 'lodash'
import {
  Web
} from '@/store/web'
import
  ChangeEIT
 from '@/components/Dev/ChangeAgencyEIT.vue'
import
  GenIIT
  from '@/components/Dev/GenIIT.vue'
import
  GenEIT
  from '@/components/Dev/GenEIT.vue'
import
  CloneIIT
  from '@/components/Dev/CloneIIT.vue'
@Component({
  components: {
    ChangeEIT,GenIIT,GenEIT,CloneIIT
  },
})
export default class HomeDev extends Vue {
  tabs:number = 0
  response:boolean = false
  async created() {
    let user = await User.getProfile();
    this.response = user.is_superuser
  }
}
