






























































import {
  Component,
  Vue
} from 'vue-property-decorator';
import {
  Core
} from '@/store/core'
import {
  Auth
} from '@/store/auth'
import {
  User
} from '@/store/user'
import _ from 'lodash'
import {
  Web
} from '@/store/web'

@Component({
  components: {

  },
})
export default class Home extends Vue {

  env:any = {
    years:null,
    agencies:null
  }

  agency:any = null
  agencyChoose:any = null
  year:any = null
  suggestion:any = null
  eit:any = null
  response:boolean = false
  alert:[] = []

  async loadEnv(){
    this.env.agencies = await Core.getHttp(`/api/ita/v1/agency/`)
    this.env.years = await Core.getHttp(`/api/eit/v2/year/`)
  }
  async created() {
    await this.loadEnv();
    this.response = true
  }

  async getListEIT(){
      this.suggestion = await Core.getHttp(`/api/eit/v2/answersuggestioneit/?agency=${this.agency.id}&year=${this.year.id}`)
      this.eit =   await Core.getHttp(`/api/eit/v2/answerissueeit/?agency=${this.agency.id}&year=${this.year.id}`)
  }

  perloadEIT:number = 0;
  perloadSug:number = 0;
  async convertAgency(){
      let check = confirm('are you sure')
      if(check){
        for (let i=0; i < this.eit.length; i++){
          let per = i
          let data = this.eit[i]
          data.agency = this.agencyChoose.id
          console.log(data)

          await Core.putHttp(`/api/eit/v2/answerissueeit/${data.id}/`,data)

          await this.sleep(800).then(() => {  this.perloadEIT = per+1 })

        }
        for (let j=0; j < this.suggestion.length; j++){
          let per = j
          let data = this.suggestion[j]
          data.agency = this.agencyChoose.id
          await Core.putHttp(`/api/eit/v2/answersuggestioneit/${data.id}/`,data)
          console.log(data)
          await this.sleep(1000).then(() => {  this.perloadSug = per+1 })

        }


      }
  }

  async sleep(ms:any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }





  prePrint(){
    window.print();
  }

}
